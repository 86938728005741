import SheCanCodeProfilePhoto from "../images/ruth.jpg"
import WorkingProfilePhoto from "../images/working-profile.jpg"

export const blogPosts = [
  {
    image: WorkingProfilePhoto,
    date: 'January 04, 2023',
    isFeatured: false,
    isHero: true,
    link: '/blog/frontdesk-to-frontend',
    title: 'Front desk to Frontend: My journey into software engineering',
    description: 'Not many people know this about me, but when I first stepped into the world of work I was an estate agent, ten months later, I was fired. As we start the New Year, 2023, I thought it apt to reflect on my career journey from the beginning.'
  },
  {
    image: SheCanCodeProfilePhoto,
    date: 'July 10, 2020',
    isFeatured: true,
    isHero: false,
    link: 'https://shecancode.io/blog/meet-ruth-uwemedimo-senior-software-engineer-at-lantum',
    title: 'Meet Ruth Uwemedimo, Senior Software Engineer at Lantum',
    description: 'We recently caught up with Ruth Uwemedimo, Senior Software Engineer at Lantum. In this brilliant interview, Ruth shares a whole host of valuable resources to help budding software developers understand how to start their careers in tech.'
  },
  {
    image: 'https://images.unsplash.com/photo-1569748130764-3fed0c102c59?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80',
    date: 'Jun 29, 2020',
    isFeatured: false,
    isHero: false,
    link: '/blog/top-tips-to-finding-your-way-around-a-new-codebase',
    title: 'Top tips to finding your way around a new codebase',
    description: 'Almost a year ago, I joined Lantum: a company with a mission to save the NHS £1 billion in staffing agency fees. The team at Lantum are great, and everyone is so welcoming to new joiners, which helped me enormously to feel comfortable in my new role as a Senior Frontend Engineer...'
  },
  {
    image: 'https://miro.medium.com/max/4000/1*GGSwLXhkkySIwm0wisRfGA.jpeg',
    date: 'May 13, 2019',
    isFeatured: false,
    isHero: false,
    link: '/blog/alex-and-ruth-go-to-events-reactJS-girls-conference-2019',
    title: 'Alex and Ruth go to events: ReactJS Girls Conference 2019',
    description: 'Alex and Ruth: The two of us were really excited to go to the conference together. We’re at very different stages of learning but the ReactJS Girls Conference is open to all those interested in JavaScript and React...'
  },
  {
    image: 'https://miro.medium.com/max/1400/1*CFsleI3iunHMeZtIVq0fSQ.jpeg',
    date: 'Dec 18, 2018',
    isFeatured: true,
    isHero: false,
    link: 'https://medium.com/free-code-camp/how-two-friends-changed-careers-learned-to-code-and-built-a-startup-e40c0b060de8',
    title: 'How two friends changed careers, learned to code, and built a startup',
    description: 'Katerina Pascoulis and Ruth Uwemedimo talk about what they learned from freelancing and why they love working together at Personably.'
  },
  {
    image: 'https://miro.medium.com/max/1400/1*Wq6c-ZFeimwPTgmrXtY6wA.jpeg',
    date: 'Oct 4, 2018',
    isFeatured: false,
    isHero: false,
    link: '/blog/the-story-of-building-macaw-ui',
    title: 'The story of building Macaw UI',
    description: 'We now have our own component library — Macaw UI, which is how we’ll be keeping our design and brand consistent throughout our product...'
  },
  {
    image: 'https://miro.medium.com/max/7776/1*z7aahOChZZxNfMInuGUN4w.jpeg',
    date: 'Apr 26, 2018',
    isFeatured: false,
    isHero: false,
    link: '/blog/how-we-got-cypress-running-solid-tests-in-continuous-integration',
    title: 'How we got Cypress running solid tests in Continuous Integration',
    description: 'It was time to start writing end to end tests for Personably. We wanted to maintain a smooth UX and save our users from experiencing broken flows...'
  },
]